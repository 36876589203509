define("ember-cli-google-recaptcha/services/g-recaptcha", ["exports", "@ember/service", "@ember/application", "@ember/object", "rsvp", "@ember/utils"], function (_exports, _service, _application, _object, _rsvp, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * Ember.js service for g-recaptcha. The service is the preferred approach because
   * we need to ensure the recaptcha script is not loaded multiple times, while allowing
   * the components to invoke methods on the grecaptcha window object.
   *
   * The service is used by reCAPTCHA-v2 and reCAPTCHA-invisible.
   */
  class GRecaptcha extends _service.default {
    constructor() {
      super(...arguments);
      const ENV = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      Object.defineProperty(this, 'siteKey', {
        value: (0, _object.get)(ENV, 'ember-cli-google.recaptcha.siteKey')
      });
    }
    /**
     * Renders the container as a reCAPTCHA widget and returns the ID of the newly
     * created widget.
     *
     * @param container
     * @param params
     */


    async render(container, params) {
      const options = Object.assign({
        sitekey: this.siteKey
      }, params);
      const grecaptcha = await this.getInstance();
      return grecaptcha.render(container, options);
    }
    /**
     * Manually invoke the reCAPTCHA check. Used if the invisible reCAPTCHA is on a
     * div instead of a button.
     *
     * @param widgetId
     */


    async execute(widgetId) {
      const grecaptcha = await this.getInstance();
      return grecaptcha.execute(widgetId);
    }
    /**
     * Resets the reCAPTCHA widget.
     *
     * @param widgetId
     */


    async reset(widgetId) {
      const grecaptcha = await this.getInstance();
      return await grecaptcha.reset(widgetId);
    }
    /**
     * Gets the response for the reCAPTCHA widget.
     *
     * @param widgetId
     */


    async getResponse(widgetId) {
      const grecaptcha = await this.getInstance();
      return grecaptcha.getResponse(widgetId);
    }
    /**
     * Get the singleton instance of the underling recaptcha instance.
     *
     * @return {*}
     */


    getInstance() {
      if ((0, _utils.isPresent)(this._instance)) {
        return this._instance;
      }

      this._instance = new _rsvp.Promise((resolve, reject) => {
        // This is for Fastboot support.
        if ((0, _utils.isNone)(window) || (0, _utils.isNone)(window.document)) {
          return resolve();
        }

        window._grecaptcha_onload = () => resolve(window.grecaptcha); // Load the recaptcha script from Google. We do not use jQuery because it is
        // easier and faster to load the script manually by injecting the script tag
        // into the head.


        const script = document.createElement('script');

        script.onerror = err => reject(err);

        script.defer = true;
        script.async = true;
        script.src = 'https://www.google.com/recaptcha/api.js?onload=_grecaptcha_onload&render=explicit';
        const head = document.querySelector('head');
        head.appendChild(script);
      });
      return this._instance;
    }

  }

  _exports.default = GRecaptcha;
});