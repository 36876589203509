define("ember-cli-google-recaptcha/components/g-recaptcha-invisible", ["exports", "@ember/component", "@ember/template-factory", "ember-cli-google-recaptcha/-private/g-recaptcha-base", "@ember/object", "@ember/utils"], function (_exports, _component, _templateFactory, _gRecaptchaBase, _object, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="g-recaptcha-invisible"
    {{did-insert this.attachToSubmit}}
    {{did-update this.run @execute @reset}}>
    {{yield}}
    <div class="g-recaptcha g-recaptcha--invisible" {{did-insert this.didInsert}}></div>
  </div>
  
  */
  {
    "id": "CdBf5jLt",
    "block": "[[[11,0],[24,0,\"g-recaptcha-invisible\"],[4,[38,0],[[30,0,[\"attachToSubmit\"]]],null],[4,[38,1],[[30,0,[\"run\"]],[30,1],[30,2]],null],[12],[1,\"\\n  \"],[18,3,null],[1,\"\\n  \"],[11,0],[24,0,\"g-recaptcha g-recaptcha--invisible\"],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[12],[13],[1,\"\\n\"],[13],[1,\"\\n\"]],[\"@execute\",\"@reset\",\"&default\"],false,[\"did-insert\",\"did-update\",\"yield\"]]",
    "moduleName": "ember-cli-google-recaptcha/components/g-recaptcha-invisible.hbs",
    "isStrictMode": false
  });

  /**
   * @class GRecaptchaInvisibleComponent
   *
   * The component for invisible reRECAPTCHA
   */
  let GRecaptchaInvisibleComponent = (_class = class GRecaptchaInvisibleComponent extends _gRecaptchaBase.default {
    getOptions() {
      return {
        badge: this.badge
      };
    }

    get size() {
      return 'invisible';
    }

    get type() {
      return this.args.type || 'image';
    }

    get badge() {
      return this.args.badge || 'bottomright';
    }

    async run(element, _ref2) {
      let [execute, reset] = _ref2;

      if (reset) {
        await this.reset();
      }

      if (execute) {
        await this.execute();
      }
    }

    attachToSubmit(element) {
      const submitButton = element.querySelector('button[type="submit"]');

      if ((0, _utils.isPresent)(submitButton)) {
        submitButton.addEventListener('click', this.submit.bind(this), true);
      }
    }

    async submit(ev) {
      ev.stopPropagation();
      ev.preventDefault();

      if ((0, _utils.isPresent)(this.response)) {
        await this.reset();
      }

      await this.execute();
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "run", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "run"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "attachToSubmit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "attachToSubmit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "submit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "submit"), _class.prototype)), _class);
  _exports.default = GRecaptchaInvisibleComponent;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GRecaptchaInvisibleComponent);
});