define("ember-cli-google-recaptcha/components/g-recaptcha-v2", ["exports", "@ember/component", "@ember/template-factory", "ember-cli-google-recaptcha/-private/g-recaptcha-base", "@ember/object"], function (_exports, _component, _templateFactory, _gRecaptchaBase, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _class;

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="g-recaptcha g-recaptcha--v2" data-tabindex={{this.tabIndex}}
      {{did-insert this.didInsert}}
      {{did-update this.run @reset}}>
  </div>
  */
  {
    "id": "c90vp8lq",
    "block": "[[[11,0],[24,0,\"g-recaptcha g-recaptcha--v2\"],[16,\"data-tabindex\",[30,0,[\"tabIndex\"]]],[4,[38,0],[[30,0,[\"didInsert\"]]],null],[4,[38,1],[[30,0,[\"run\"]],[30,1]],null],[12],[1,\"\\n\"],[13]],[\"@reset\"],false,[\"did-insert\",\"did-update\"]]",
    "moduleName": "ember-cli-google-recaptcha/components/g-recaptcha-v2.hbs",
    "isStrictMode": false
  });

  let GRecaptchaV2Component = (_class = class GRecaptchaV2Component extends _gRecaptchaBase.default {
    get tabIndex() {
      return this.args.tabIndex || 0;
    }

    get theme() {
      return this.args.theme || 'light';
    }

    get type() {
      return this.args.type || 'image';
    }

    get size() {
      return this.args.size || 'normal';
    }

    async run(element, _ref2) {
      let [reset] = _ref2;

      if (reset) {
        await this.reset();
      }
    }

  }, (_applyDecoratedDescriptor(_class.prototype, "run", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "run"), _class.prototype)), _class);
  _exports.default = GRecaptchaV2Component;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GRecaptchaV2Component);
});