define("ember-cli-google-recaptcha/-private/g-recaptcha-base", ["exports", "@glimmer/component", "@ember/service", "@ember/utils", "@ember/object", "@glimmer/tracking"], function (_exports, _component, _service, _utils, _object, _tracking) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _descriptor2, _descriptor3;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function noop() {}

  let GRecaptchaBase = (_dec = (0, _service.inject)('g-recaptcha'), (_class = class GRecaptchaBase extends _component.default {
    constructor() {
      super(...arguments);

      _initializerDefineProperty(this, "grecaptcha", _descriptor, this);

      _initializerDefineProperty(this, "widgetId", _descriptor2, this);

      _initializerDefineProperty(this, "response", _descriptor3, this);
    }

    get options() {
      const options = Object.assign({
        size: this.size,
        type: this.type,
        theme: this.theme,
        tabindex: this.tabIndex,
        callback: this._callback.bind(this),
        'expired-callback': this._expiredCallback.bind(this)
      }, this.getOptions());

      if ((0, _utils.isPresent)(this.args.siteKey)) {
        options.sitekey = this.args.siteKey;
      }

      return options;
    }

    async didInsert(element) {
      this.widgetId = await this.grecaptcha.render(element, this.options); // Let the subclasses know that we have rendered the recaptcha.

      await this.didRender(element);
    }

    get theme() {
      return this.args.theme || 'light';
    }

    get tabIndex() {
      return this.args.tabIndex || 0;
    }

    getOptions() {}

    async didRender() {}
    /**
     * Callback function to be executed when the recaptcha response expires and the
     * user needs to solve a new CAPTCHA.
     *
     * @private
     */


    _expiredCallback() {
      (this.args.expired || noop)();
    }
    /**
     * Reset the recaptcha component.
     */


    async reset() {
      // Reset the widget, and then reset the response. After we reset the
      // object, let's notify the subclasses that we actually reset the object.
      await this.grecaptcha.reset(this.widgetId);
      this.response = null;
    }
    /**
     * Execute the recaptcha response.
     */


    async execute() {
      try {
        // Notify the client we started the verification process.
        this.verifying(true);
        await this.grecaptcha.execute(this.widgetId);
      } catch (err) {
        // We are no longer verifying anything. Reset the state, and then
        // rethrow the error.
        this.verifying(false);
        throw err;
      }
    }

    get verifying() {
      return this.args.verifying || noop;
    }

    didVerify(response) {}

    get verified() {
      return this.args.verified || noop;
    }
    /**
     * Callback when the component finished executing.
     */


    didExecute() {}
    /**
     * Callback when the component resets.
     */


    didReset() {}
    /**
     * Handle errors from the callback method.
     *
     * @param err       The error received.
     */


    didError(err) {}
    /**
     * The name of your callback function to be executed when the user submits
     * a successful CAPTCHA response. The user's response, g-recaptcha-response,
     * will be the input for your callback function.
     *
     * @private
     */


    async _callback() {
      try {
        this.response = await this.grecaptcha.getResponse(this.widgetId); // Let the subclass know we have verified the response.

        this.didVerify(this.response); // Let the client know we have verified the user.

        this.verified(this.response);
      } catch (err) {
        this.didError(err);
      } finally {
        //  We also need to let the client know the component has left the verifying
        //  state. This is different from the verified event.
        this.verifying(false);
      }
    }

  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "grecaptcha", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "widgetId", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "response", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "didInsert", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "didInsert"), _class.prototype)), _class));
  _exports.default = GRecaptchaBase;
});